import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import InputUsers from "../Pages/Users/InputUsers";
import ListUsers from "../Pages/Users/ListUsers";
import DataSekolah from "../Pages/DataSekolah";
import ListDaftarPelajaran from "../Pages/MataPelajaran";
import ListKelas from "../Pages/DataKelas";
import DataSiswa from "../Pages/DataSiswa";
import ListMataPelajaran from "../Pages/MataPelajaran/MataPelajaran";

const authProtectedRoutes = [
   //dashboard
   { path: "/dashboard", component: <Dashboard /> },

   // Users
   { path: "/input-users", component: <InputUsers /> },
   { path: "/list-users", component: <ListUsers /> },

   //school
   { path: "/data-sekolah", component: <DataSekolah /> },

   //school
   { path: "/data-kelas", component: <ListKelas /> },

   //school
   { path: "/data-siswa", component: <DataSiswa /> },

   //mata-pelajaran
   { path: "/mata-pelajaran", component: <ListMataPelajaran /> },

   //pelajaran
   { path: "/list-daftar-pelajaran", component: <ListDaftarPelajaran /> },

   // Profile
   { path: "/userprofile", component: <UserProfile /> },

   // this route should be at the end of all other routes
   // eslint-disable-next-line react/display-name
   {
      path: "/",
      exact: true,
      component: <Navigate to="/dashboard" />,
   },
];

const publicRoutes = [
   // Authentication Page
   { path: "/logout", component: <Logout /> },
   { path: "/login", component: <Login /> },
   { path: "/forgot-password", component: <ForgetPasswordPage /> },
   { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
