import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ListMataPelajaran = () => {
   const [modalVisible, setModalVisible] = useState(false);
   const [isEdit, setIsEdit] = useState(false);
   const [modalDelete, setModalDelete] = useState(false);
   const [currentSubject, setCurrentSubject] = useState({});

   const [subjects, setSubjects] = useState([
      { id: "MP01", namaMapel: "Matematika", kelas: "10", kelompokMapel: "Wajib", kurikulum: "K13", tahunPelajaran: "2023/2024", jurusan: "Teknik Informatika" },
      { id: "MP02", namaMapel: "Bahasa Inggris", kelas: "11", kelompokMapel: "Wajib", kurikulum: "K13", tahunPelajaran: "2023/2024", jurusan: "Akuntansi" },
      { id: "MP03", namaMapel: "Pemrograman Dasar", kelas: "10", kelompokMapel: "Produktif", kurikulum: "K13", tahunPelajaran: "2023/2024", jurusan: "Teknik Komputer Jaringan" },
   ]);

   const toggleModal = () => {
      setModalVisible(!modalVisible);
   };

   const toggleDeleteModal = () => {
      setModalDelete(!modalDelete);
   };

   const handleEdit = (subject) => {
      setCurrentSubject(subject);
      setIsEdit(true);
      toggleModal();
   };

   const handleAdd = () => {
      setCurrentSubject({ id: "", namaMapel: "", kelas: "", kelompokMapel: "", kurikulum: "", tahunPelajaran: "", jurusan: "" });
      setIsEdit(false);
      toggleModal();
   };

   const handleSave = (e) => {
      e.preventDefault();
      if (isEdit) {
         setSubjects(subjects.map((subject) => (subject.id === currentSubject.id ? currentSubject : subject)));
      } else {
         setSubjects([...subjects, { ...currentSubject, id: `MP${subjects.length + 1}` }]);
      }
      toggleModal();
   };

   const prepareDelete = (subject) => {
      setCurrentSubject(subject);
      toggleDeleteModal();
   };

   const confirmDelete = () => {
      setSubjects(subjects.filter((subject) => subject.id !== currentSubject.id));
      toggleDeleteModal();
      setCurrentSubject({});
   };

   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid>
               <Breadcrumbs title="Mata Pelajaran" breadcrumbItem="List Mata Pelajaran" />

               <Row>
                  <Col lg={12}>
                     <Card>
                        <CardHeader>
                           <h4 className="card-title mb-0">Table List Mata Pelajaran</h4>
                        </CardHeader>

                        <CardBody>
                           <div id="subjectList">
                              <Row className="g-4 mb-3">
                                 <Col className="col-sm-auto">
                                    <div className="d-flex gap-1">
                                       <Button color="primary" onClick={handleAdd}>
                                          Tambah Mata Pelajaran
                                       </Button>
                                    </div>
                                 </Col>
                                 <Col className="col-sm">
                                    <div className="d-flex justify-content-sm-end">
                                       <div className="search-box ms-2">
                                          <input type="text" className="form-control search" placeholder="Search..." />
                                          <i className="ri-search-line search-icon"></i>
                                       </div>
                                    </div>
                                 </Col>
                              </Row>

                              <div className="table-responsive table-card mt-3 mb-1">
                                 <table className="table align-middle table-nowrap" id="subjectTable">
                                    <thead className="table-light">
                                       <tr>
                                          <th>ID</th>
                                          <th>Nama Mata Pelajaran</th>
                                          <th>Kelas</th>
                                          <th>Kelompok Mapel</th>
                                          <th>Kurikulum</th>
                                          <th>Tahun Pelajaran</th>
                                          <th>Jurusan</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {subjects.map((subject) => (
                                          <tr key={subject.id}>
                                             <td>{subject.id}</td>
                                             <td>{subject.namaMapel}</td>
                                             <td>{subject.kelas}</td>
                                             <td>{subject.kelompokMapel}</td>
                                             <td>{subject.kurikulum}</td>
                                             <td>{subject.tahunPelajaran}</td>
                                             <td>{subject.jurusan}</td>
                                             <td>
                                                <div className="d-flex gap-2">
                                                   <button className="btn btn-sm btn-success" onClick={() => handleEdit(subject)}>
                                                      Ubah
                                                   </button>
                                                   <button className="btn btn-sm btn-danger" onClick={() => prepareDelete(subject)}>
                                                      Hapus
                                                   </button>
                                                </div>
                                             </td>
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>

         {/* Add/Edit Modal */}
         <Modal isOpen={modalVisible} toggle={toggleModal} centered>
            <ModalHeader className="bg-light p-3" toggle={toggleModal}>
               {isEdit ? "Edit Mata Pelajaran" : "Tambah Mata Pelajaran"}
            </ModalHeader>
            <form onSubmit={handleSave}>
               <ModalBody>
                  <div className="mb-3">
                     <label htmlFor="namaMapel-field" className="form-label">
                        Nama Mata Pelajaran
                     </label>
                     <input type="text" id="namaMapel-field" className="form-control" placeholder="Enter Nama Mata Pelajaran" value={currentSubject.namaMapel} onChange={(e) => setCurrentSubject({ ...currentSubject, namaMapel: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="kelas-field" className="form-label">
                        Kelas
                     </label>
                     <input type="text" id="kelas-field" className="form-control" placeholder="Enter Kelas" value={currentSubject.kelas} onChange={(e) => setCurrentSubject({ ...currentSubject, kelas: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="kelompokMapel-field" className="form-label">
                        Kelompok Mapel
                     </label>
                     <input type="text" id="kelompokMapel-field" className="form-control" placeholder="Enter Kelompok Mapel" value={currentSubject.kelompokMapel} onChange={(e) => setCurrentSubject({ ...currentSubject, kelompokMapel: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="kurikulum-field" className="form-label">
                        Kurikulum
                     </label>
                     <input type="text" id="kurikulum-field" className="form-control" placeholder="Enter Kurikulum" value={currentSubject.kurikulum} onChange={(e) => setCurrentSubject({ ...currentSubject, kurikulum: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="tahunPelajaran-field" className="form-label">
                        Tahun Pelajaran
                     </label>
                     <input type="text" id="tahunPelajaran-field" className="form-control" placeholder="Enter Tahun Pelajaran" value={currentSubject.tahunPelajaran} onChange={(e) => setCurrentSubject({ ...currentSubject, tahunPelajaran: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="jurusan-field" className="form-label">
                        Jurusan
                     </label>
                     <input type="text" id="jurusan-field" className="form-control" placeholder="Enter Jurusan" value={currentSubject.jurusan} onChange={(e) => setCurrentSubject({ ...currentSubject, jurusan: e.target.value })} required />
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button type="button" color="light" onClick={toggleModal}>
                     Batal
                  </Button>
                  <Button type="submit" color="success">
                     {isEdit ? "Update Mata Pelajaran" : "Tambah Mata Pelajaran"}
                  </Button>
               </ModalFooter>
            </form>
         </Modal>

         {/* Remove Confirmation Modal */}
         <Modal isOpen={modalDelete} toggle={toggleDeleteModal} centered>
            <ModalBody>
               <div className="mt-2 text-center">
                  <h4>Anda yakin menghapus data?</h4>
                  <p>Data yang dihapus tidak bisa dikembalikan</p>
               </div>
               <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <Button type="button" color="light" onClick={toggleDeleteModal}>
                     Batal
                  </Button>
                  <Button type="button" color="danger" onClick={confirmDelete}>
                     Ya, Hapus!
                  </Button>
               </div>
            </ModalBody>
         </Modal>
      </React.Fragment>
   );
};

export default ListMataPelajaran;
