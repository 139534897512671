import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ListKelas = () => {
   const [modalVisible, setModalVisible] = useState(false);
   const [isEdit, setIsEdit] = useState(false);
   const [modalDelete, setModalDelete] = useState(false);
   const [currentClass, setCurrentClass] = useState({});

   const [classes, setClasses] = useState([
      { id: "KL01", namaKelas: "Kelas 1A", namaWaliKelas: "Budi Santoso" },
      { id: "KL02", namaKelas: "Kelas 2B", namaWaliKelas: "Siti Aisyah" },
      { id: "KL03", namaKelas: "Kelas 3C", namaWaliKelas: "Ahmad Fauzi" },
      { id: "KL04", namaKelas: "Kelas 4D", namaWaliKelas: "Rina Kurniawati" },
   ]);

   const toggleModal = () => {
      setModalVisible(!modalVisible);
   };

   const toggleDeleteModal = () => {
      setModalDelete(!modalDelete);
   };

   const handleEdit = (kelas) => {
      setCurrentClass(kelas);
      setIsEdit(true);
      toggleModal();
   };

   const handleAdd = () => {
      setCurrentClass({ id: "", namaKelas: "", namaWaliKelas: "" });
      setIsEdit(false);
      toggleModal();
   };

   const handleSave = (e) => {
      e.preventDefault();
      if (isEdit) {
         setClasses(classes.map((kelas) => (kelas.id === currentClass.id ? currentClass : kelas)));
      } else {
         setClasses([...classes, { ...currentClass, id: `KL${classes.length + 1}` }]);
      }
      toggleModal();
   };

   const prepareDelete = (kelas) => {
      setCurrentClass(kelas);
      toggleDeleteModal();
   };

   const confirmDelete = () => {
      setClasses(classes.filter((kelas) => kelas.id !== currentClass.id));
      toggleDeleteModal();
      setCurrentClass({});
   };

   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid>
               <Breadcrumbs title="Kelas" breadcrumbItem="Daftar Kelas" />

               <Row>
                  <Col lg={12}>
                     <Card>
                        <CardHeader>
                           <h4 className="card-title mb-0">Table Daftar Kelas</h4>
                        </CardHeader>

                        <CardBody>
                           <div id="classList">
                              <Row className="g-4 mb-3">
                                 <Col className="col-sm-auto">
                                    <div className="d-flex gap-1">
                                       <Button color="primary" onClick={handleAdd}>
                                          Tambah Kelas
                                       </Button>
                                    </div>
                                 </Col>
                                 <Col className="col-sm">
                                    <div className="d-flex justify-content-sm-end">
                                       <div className="search-box ms-2">
                                          <input type="text" className="form-control search" placeholder="Search..." />
                                          <i className="ri-search-line search-icon"></i>
                                       </div>
                                    </div>
                                 </Col>
                              </Row>

                              <div className="table-responsive table-card mt-3 mb-1">
                                 <table className="table align-middle table-nowrap" id="classTable">
                                    <thead className="table-light">
                                       <tr>
                                          <th>ID</th>
                                          <th>Nama Kelas</th>
                                          <th>Nama Wali Kelas</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {classes.map((kelas) => (
                                          <tr key={kelas.id}>
                                             <td>{kelas.id}</td>
                                             <td>{kelas.namaKelas}</td>
                                             <td>{kelas.namaWaliKelas}</td>
                                             <td>
                                                <div className="d-flex gap-2">
                                                   <button className="btn btn-sm btn-success" onClick={() => handleEdit(kelas)}>
                                                      Ubah
                                                   </button>
                                                   <button className="btn btn-sm btn-danger" onClick={() => prepareDelete(kelas)}>
                                                      Hapus
                                                   </button>
                                                </div>
                                             </td>
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>

         {/* Add/Edit Modal */}
         <Modal isOpen={modalVisible} toggle={toggleModal} centered>
            <ModalHeader className="bg-light p-3" toggle={toggleModal}>
               {isEdit ? "Edit Kelas" : "Tambah Kelas"}
            </ModalHeader>
            <form onSubmit={handleSave}>
               <ModalBody>
                  <div className="mb-3">
                     <label htmlFor="namaKelas-field" className="form-label">
                        Nama Kelas
                     </label>
                     <input type="text" id="namaKelas-field" className="form-control" placeholder="Enter Nama Kelas" value={currentClass.namaKelas} onChange={(e) => setCurrentClass({ ...currentClass, namaKelas: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="namaWaliKelas-field" className="form-label">
                        Nama Wali Kelas
                     </label>
                     <input type="text" id="namaWaliKelas-field" className="form-control" placeholder="Enter Nama Wali Kelas" value={currentClass.namaWaliKelas} onChange={(e) => setCurrentClass({ ...currentClass, namaWaliKelas: e.target.value })} required />
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button type="button" color="light" onClick={toggleModal}>
                     Batal
                  </Button>
                  <Button type="submit" color="success">
                     {isEdit ? "Update Kelas" : "Tambah Kelas"}
                  </Button>
               </ModalFooter>
            </form>
         </Modal>

         {/* Remove Confirmation Modal */}
         <Modal isOpen={modalDelete} toggle={toggleDeleteModal} centered>
            <ModalBody>
               <div className="mt-2 text-center">
                  <h4>Anda yakin menghapus data?</h4>
                  <p>Data yang dihapus tidak bisa dikembalikan</p>
               </div>
               <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <Button type="button" color="light" onClick={toggleDeleteModal}>
                     Batal
                  </Button>
                  <Button type="button" color="danger" onClick={confirmDelete}>
                     Ya, Hapus!
                  </Button>
               </div>
            </ModalBody>
         </Modal>
      </React.Fragment>
   );
};

export default ListKelas;
