import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Flatpickr from "react-flatpickr";

const DataSiswa = () => {
   const [modalVisible, setModalVisible] = useState(false);
   const [isEdit, setIsEdit] = useState(false);
   const [modalDelete, setModalDelete] = useState(false);
   const [currentStudent, setCurrentStudent] = useState({});

   const [students, setStudents] = useState([
      { id: "S001", NISN: "1234567890", namaSiswa: "Ahmad Surya", kelas: "10A", tanggalLahir: "2005-06-15", tahunMasuk: "2021", status: "Aktif" },
      { id: "S002", NISN: "0987654321", namaSiswa: "Lestari Putri", kelas: "11B", tanggalLahir: "2004-09-20", tahunMasuk: "2020", status: "Aktif" },
      { id: "S003", NISN: "5678901234", namaSiswa: "Budi Santoso", kelas: "12A", tanggalLahir: "2003-12-05", tahunMasuk: "2019", status: "Lulus" },
   ]);

   const toggleModal = () => {
      setModalVisible(!modalVisible);
   };

   const toggleDeleteModal = () => {
      setModalDelete(!modalDelete);
   };

   const handleEdit = (student) => {
      setCurrentStudent(student);
      setIsEdit(true);
      toggleModal();
   };

   const handleAdd = () => {
      setCurrentStudent({ id: "", NISN: "", namaSiswa: "", kelas: "", tanggalLahir: "", tahunMasuk: "", status: "" });
      setIsEdit(false);
      toggleModal();
   };

   const handleSave = (e) => {
      e.preventDefault();
      if (isEdit) {
         setStudents(students.map((student) => (student.id === currentStudent.id ? currentStudent : student)));
      } else {
         setStudents([...students, { ...currentStudent, id: `S${students.length + 1}` }]);
      }
      toggleModal();
   };

   const prepareDelete = (student) => {
      setCurrentStudent(student);
      toggleDeleteModal();
   };

   const confirmDelete = () => {
      setStudents(students.filter((student) => student.id !== currentStudent.id));
      toggleDeleteModal();
      setCurrentStudent({});
   };

   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid>
               <Breadcrumbs title="Siswa" breadcrumbItem="Data Siswa" />

               <Row>
                  <Col lg={12}>
                     <Card>
                        <CardHeader>
                           <h4 className="card-title mb-0">Table Data Siswa</h4>
                        </CardHeader>

                        <CardBody>
                           <div id="studentList">
                              <Row className="g-4 mb-3">
                                 <Col className="col-sm-auto">
                                    <div className="d-flex gap-1">
                                       <Button color="primary" onClick={handleAdd}>
                                          Tambah Siswa
                                       </Button>
                                    </div>
                                 </Col>
                                 <Col className="col-sm">
                                    <div className="d-flex justify-content-sm-end">
                                       <div className="search-box ms-2">
                                          <input type="text" className="form-control search" placeholder="Search..." />
                                          <i className="ri-search-line search-icon"></i>
                                       </div>
                                    </div>
                                 </Col>
                              </Row>

                              <div className="table-responsive table-card mt-3 mb-1">
                                 <table className="table align-middle table-nowrap" id="studentTable">
                                    <thead className="table-light">
                                       <tr>
                                          <th>ID</th>
                                          <th>NISN</th>
                                          <th>Nama Siswa</th>
                                          <th>Kelas</th>
                                          <th>Tanggal Lahir</th>
                                          <th>Tahun Masuk</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {students.map((student) => (
                                          <tr key={student.id}>
                                             <td>{student.id}</td>
                                             <td>{student.NISN}</td>
                                             <td>{student.namaSiswa}</td>
                                             <td>{student.kelas}</td>
                                             <td>{student.tanggalLahir}</td>
                                             <td>{student.tahunMasuk}</td>
                                             <td>{student.status}</td>
                                             <td>
                                                <div className="d-flex gap-2">
                                                   <button className="btn btn-sm btn-success" onClick={() => handleEdit(student)}>
                                                      Ubah
                                                   </button>
                                                   <button className="btn btn-sm btn-danger" onClick={() => prepareDelete(student)}>
                                                      Hapus
                                                   </button>
                                                </div>
                                             </td>
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>

         {/* Add/Edit Modal */}
         <Modal isOpen={modalVisible} toggle={toggleModal} centered>
            <ModalHeader className="bg-light p-3" toggle={toggleModal}>
               {isEdit ? "Edit Siswa" : "Tambah Siswa"}
            </ModalHeader>
            <form onSubmit={handleSave}>
               <ModalBody>
                  <div className="mb-3">
                     <label htmlFor="nisn-field" className="form-label">
                        NISN
                     </label>
                     <input type="text" id="nisn-field" className="form-control" placeholder="Enter NISN" value={currentStudent.NISN} onChange={(e) => setCurrentStudent({ ...currentStudent, NISN: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="namaSiswa-field" className="form-label">
                        Nama Siswa
                     </label>
                     <input type="text" id="namaSiswa-field" className="form-control" placeholder="Enter Nama Siswa" value={currentStudent.namaSiswa} onChange={(e) => setCurrentStudent({ ...currentStudent, namaSiswa: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="kelas-field" className="form-label">
                        Kelas
                     </label>
                     <input type="text" id="kelas-field" className="form-control" placeholder="Enter Kelas" value={currentStudent.kelas} onChange={(e) => setCurrentStudent({ ...currentStudent, kelas: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="tanggalLahir-field" className="form-label">
                        Tanggal Lahir
                     </label>
                     <Flatpickr className="form-control" value={currentStudent.tanggalLahir} options={{ dateFormat: "Y-m-d" }} onChange={(date) => setCurrentStudent({ ...currentStudent, tanggalLahir: date[0].toISOString().split("T")[0] })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="tahunMasuk-field" className="form-label">
                        Tahun Masuk
                     </label>
                     <input type="text" id="tahunMasuk-field" className="form-control" placeholder="Enter Tahun Masuk" value={currentStudent.tahunMasuk} onChange={(e) => setCurrentStudent({ ...currentStudent, tahunMasuk: e.target.value })} required />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="status-field" className="form-label">
                        Status
                     </label>
                     <select className="form-control" id="status-field" value={currentStudent.status} onChange={(e) => setCurrentStudent({ ...currentStudent, status: e.target.value })} required>
                        <option value="">Select Status</option>
                        <option value="Aktif">Aktif</option>
                        <option value="Tidak Aktif">Tidak Aktif</option>
                        <option value="Lulus">Lulus</option>
                     </select>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button type="button" color="light" onClick={toggleModal}>
                     Batal
                  </Button>
                  <Button type="submit" color="success">
                     {isEdit ? "Update Siswa" : "Tambah Siswa"}
                  </Button>
               </ModalFooter>
            </form>
         </Modal>

         {/* Remove Confirmation Modal */}
         <Modal isOpen={modalDelete} toggle={toggleDeleteModal} centered>
            <ModalBody>
               <div className="mt-2 text-center">
                  <h4>Anda yakin menghapus data?</h4>
                  <p>Data yang dihapus tidak bisa dikembalikan</p>
               </div>
               <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <Button type="button" color="light" onClick={toggleDeleteModal}>
                     Batal
                  </Button>
                  <Button type="button" color="danger" onClick={confirmDelete}>
                     Ya, Hapus!
                  </Button>
               </div>
            </ModalBody>
         </Modal>
      </React.Fragment>
   );
};

export default DataSiswa;
