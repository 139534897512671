import React, { useState } from "react";
import { Card, CardBody, Col, Row, CardTitle, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const InputUsers = () => {
   document.title = "Form Elements | Input Data Users";
   const [accessRole, setAccessRole] = useState(""); // State untuk menyimpan akses sebagai

   const handleAccessChange = (e) => {
      setAccessRole(e.target.value);
   };

   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid={true}>
               <Breadcrumbs title="Pengguna" breadcrumbItem="Input Pengguna" />

               <Row>
                  <Col>
                     <Card>
                        <CardBody>
                           <p className="card-title-desc">Inputkan data pengguna sesuai Hak Akses yang diberikan </p>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Akses Sebagai</label>
                              <div className="col-md-10">
                                 <select className="form-control" onChange={handleAccessChange}>
                                    <option value="">Pilih hak akses</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Guru">Guru</option>
                                    <option value="Guru BK">Guru BK</option>
                                    <option value="Pengajar Ekstra">Pengajar Ekstra</option>
                                    <option value="Penguji Praktik">Penguji Praktik</option>
                                    <option value="Fasilisator P5">Fasilisator P5</option>
                                    <option value="Koordinator P5">Koordinator P5</option>
                                    <option value="Siswa">Siswa</option>
                                 </select>
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                                 {accessRole === "Siswa" ? "NISN" : "NIP"}
                              </label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label htmlFor="example-search-input" className="col-md-2 col-form-label">
                                 Nama Lengkap
                              </label>
                              <div className="col-md-10">
                                 <input className="form-control" type="search" />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label htmlFor="example-email-input" className="col-md-2 col-form-label">
                                 Email
                              </label>
                              <div className="col-md-10">
                                 <input className="form-control" type="email" defaultValue="" />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label htmlFor="example-tel-input" className="col-md-2 col-form-label">
                                 Nomor Telepon/Hp
                              </label>
                              <div className="col-md-10">
                                 <input className="form-control" type="tel" />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label htmlFor="example-password-input" className="col-md-2 col-form-label">
                                 Password
                              </label>
                              <div className="col-md-10">
                                 <input className="form-control" type="password" />
                              </div>
                           </Row>
                           <Row className="mb-3 w-100 d-flex justify-content-end ">
                              <button type="button" className="btn btn-primary waves-effect waves-light w-25">
                                 Simpan <i className="ri-arrow-right-line align-middle ms-2"></i>
                              </button>
                           </Row>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
   );
};

export default InputUsers;
