import React from "react";

import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
   document.title = "Dashboard | SMK Trensains";
   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid={true}>
               <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
            </Container>
         </div>
      </React.Fragment>
   );
};

export default Dashboard;
