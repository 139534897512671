import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";

const ListUsers = () => {
   const [modal_list, setmodal_list] = useState(false);
   const [modal_delete, setmodal_delete] = useState(false);
   const [currentUser, setCurrentUser] = useState(null);

   const [users, setUsers] = useState([
      { id: "VS21", nip_nisn: "123456", nama: "Sukmana", hakAkses: "Admin", email: "sukmana@example.com", status: "Active" },
      { id: "VS22", nip_nisn: "234567", nama: "Lestari", hakAkses: "User", email: "lestari@example.com", status: "Active" },
      { id: "VS23", nip_nisn: "345678", nama: "Budiman", hakAkses: "Admin", email: "budiman@example.com", status: "Inactive" },
      { id: "VS24", nip_nisn: "456789", nama: "Cahyani", hakAkses: "User", email: "cahyani@example.com", status: "Active" },
   ]);

   function tog_list(user = null) {
      setCurrentUser(user);
      setmodal_list(!modal_list);
   }

   function tog_delete(userId) {
      setUsers(users.filter((user) => user.id !== userId));
      setmodal_delete(false);
   }

   const handleEditSubmit = (e) => {
      e.preventDefault();
      setUsers(users.map((user) => (user.id === currentUser.id ? currentUser : user)));
      setmodal_list(false);
   };

   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid>
               <Breadcrumbs title="Pengguna" breadcrumbItem="Daftar Pengguna" />

               <Row>
                  <Col lg={12}>
                     <Card>
                        <CardHeader>
                           <h4 className="card-title mb-0">Table Daftar Pengguna</h4>
                        </CardHeader>

                        <CardBody>
                           <div id="userList">
                              <div className="table-responsive table-card mt-3 mb-1">
                                 <table className="table align-middle table-nowrap" id="userTable">
                                    <thead className="table-light">
                                       <tr>
                                          <th>ID</th>
                                          <th>NIP/NISN</th>
                                          <th>Nama</th>
                                          <th>Hak Akses</th>
                                          <th>Email</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {users.map((user) => (
                                          <tr key={user.id}>
                                             <td>{user.id}</td>
                                             <td>{user.nip_nisn}</td>
                                             <td>{user.nama}</td>
                                             <td>{user.hakAkses}</td>
                                             <td>{user.email}</td>
                                             <td>
                                                <span className={`badge badge-soft-${user.status === "Active" ? "success" : "danger"} text-uppercase`}>{user.status}</span>
                                             </td>
                                             <td>
                                                <div className="d-flex gap-2">
                                                   <button className="btn btn-sm btn-success" onClick={() => tog_list(user)}>
                                                      Edit
                                                   </button>
                                                   <button className="btn btn-sm btn-danger" onClick={() => tog_delete(user.id)}>
                                                      Remove
                                                   </button>
                                                </div>
                                             </td>
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>

         {/* Edit Modal */}
         <Modal isOpen={modal_list} toggle={() => tog_list(null)} centered>
            <ModalHeader toggle={() => tog_list(null)}>Edit User</ModalHeader>
            <form onSubmit={handleEditSubmit}>
               <ModalBody>
                  <div className="mb-3">
                     <label htmlFor="nama-field" className="form-label">
                        Nama
                     </label>
                     <input type="text" className="form-control" value={currentUser?.nama || ""} onChange={(e) => setCurrentUser({ ...currentUser, nama: e.target.value })} required />
                  </div>
                  <div className="mb-3">
                     <label htmlFor="email-field" className="form-label">
                        Email
                     </label>
                     <input type="email" className="form-control" value={currentUser?.email || ""} onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })} required />
                  </div>
                  <div className="mb-3">
                     <label htmlFor="hakAkses-field" className="form-label">
                        Hak Akses
                     </label>
                     <select className="form-control" value={currentUser?.hakAkses || ""} onChange={(e) => setCurrentUser({ ...currentUser, hakAkses: e.target.value })} required>
                        <option value="">Pilih hak akses</option>
                        <option value="Admin">Admin</option>
                        <option value="Guru">Guru</option>
                        <option value="Guru BK">Guru BK</option>
                        <option value="Pengajar Ekstra">Pengajar Ekstra</option>
                        <option value="Penguji Praktik">Penguji Praktik</option>
                        <option value="Fasilisator P5">Fasilisator P5</option>
                        <option value="Koordinator P5">Koordinator P5</option>
                        <option value="Siswa">Siswa</option>
                     </select>
                  </div>
                  <div className="mb-3">
                     <label htmlFor="example-tel-input" className="col-md-2 col-form-label">
                        Hp
                     </label>
                     <div className="col-md-10">
                        <input className="form-control" type="tel" />
                     </div>
                  </div>
                  <div className="mb-3">
                     <label htmlFor="example-password-input" className="col-md-2 col-form-label">
                        Password
                     </label>
                     <div className="col-md-10">
                        <input className="form-control" type="password" />
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <Button color="secondary" onClick={() => tog_list(null)}>
                     Cancel
                  </Button>
                  <Button type="submit" color="primary">
                     Save Changes
                  </Button>
               </ModalFooter>
            </form>
         </Modal>
      </React.Fragment>
   );
};

export default ListUsers;
