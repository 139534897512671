import React, { useState } from "react";
import { Card, CardBody, Col, Row, CardTitle, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const DataSekolah = () => {
   document.title = "Data Sekolah | Input Data Sekolah";

   // Dummy data sekolah
   const initialSchoolData = {
      namaSekolah: "SMA Negeri 1 Contoh",
      NPSN: "12345678",
      alamat: "Jl. Pendidikan No. 10",
      kodePos: "12345",
      desa: "Desa Contoh",
      kecamatan: "Kecamatan Contoh",
      kabupaten: "Kabupaten Contoh",
      kota: "Kota Contoh",
      provinsi: "Provinsi Contoh",
      website: "http://sekolahcontoh.ac.id",
      email: "info@sekolahcontoh.ac.id",
   };

   const [schoolData, setSchoolData] = useState(initialSchoolData);
   const [isEditing, setIsEditing] = useState(false);

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setSchoolData({ ...schoolData, [name]: value });
   };

   const toggleEditMode = () => {
      setIsEditing(!isEditing);
   };

   return (
      <React.Fragment>
         <div className="page-content">
            <Container fluid={true}>
               <Breadcrumbs title="Sekolah" breadcrumbItem="Data Sekolah" />

               <Row>
                  <Col>
                     <Card>
                        <CardBody>
                           <p className="card-title-desc">Data sekolah dapat diperbarui dengan menekan tombol edit</p>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Nama Sekolah</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="namaSekolah" value={schoolData.namaSekolah} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">NPSN</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="NPSN" value={schoolData.NPSN} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Alamat Sekolah</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="alamat" value={schoolData.alamat} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Kode Pos</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="kodePos" value={schoolData.kodePos} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Desa/Kelurahan</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="desa" value={schoolData.desa} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Kecamatan</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="kecamatan" value={schoolData.kecamatan} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Kabupaten</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="kabupaten" value={schoolData.kabupaten} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Kota</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="kota" value={schoolData.kota} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Provinsi</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="text" name="provinsi" value={schoolData.provinsi} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Website</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="url" name="website" value={schoolData.website} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3">
                              <label className="col-md-2 col-form-label">Email</label>
                              <div className="col-md-10">
                                 <input className="form-control" type="email" name="email" value={schoolData.email} onChange={handleInputChange} disabled={!isEditing} />
                              </div>
                           </Row>
                           <Row className="mb-3 w-100 d-flex justify-content-end">
                              <button type="button" className="btn btn-primary waves-effect waves-light w-25" onClick={toggleEditMode}>
                                 {isEditing ? "Simpan" : "Edit"}
                                 <i className="ri-edit-line align-middle ms-2"></i>
                              </button>
                           </Row>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
   );
};

export default DataSekolah;
