import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { changeLayout, changeLayoutMode, changeLayoutWidth, changeSidebarTheme, changeSidebarType, changeTopbarTheme, showRightSidebarAction } from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.scss";

//Import images
import layout1 from "../../assets/images/layouts/layout-1.png";
import layout2 from "../../assets/images/layouts/layout-2.png";
import layout3 from "../../assets/images/layouts/layout-3.png";

//constants
import { layoutTypes, layoutWidthTypes, topBarThemeTypes, leftSidebarTypes, leftSideBarThemeTypes, layoutModeTypes } from "../../constants/layout";

const RightSidebar = (props) => {
   return (
      <React.Fragment>
         <div className="right-bar" id="right-bar">
            <SimpleBar style={{ height: "900px" }}>
               <div data-simplebar className="h-100">
                  <div className="rightbar-title px-3 py-4">
                     <Link
                        to="#"
                        onClick={(e) => {
                           e.preventDefault();
                           props.showRightSidebarAction(false);
                        }}
                        className="right-bar-toggle float-end"
                     >
                        <i className="mdi mdi-close noti-icon" />
                     </Link>
                     <h5 className="m-0">Pengaturan</h5>
                  </div>

                  <hr className="my-0" />

                  <div className="p-4">
                     {/* <div className="radio-toolbar">
                <span className="mb-2 d-block">Layouts</span>
                <input
                  type="radio"
                  id="radioVertical"
                  name="radioFruit"
                  value={layoutTypes.VERTICAL}
                  checked={props.layoutType === layoutTypes.VERTICAL}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayout(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioVertical">Vertical</label>
                <input
                  type="radio"
                  id="radioHorizontal"
                  name="radioFruit"
                  value={layoutTypes.HORIZONTAL}
                  checked={props.layoutType === layoutTypes.HORIZONTAL}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayout(e.target.value);
                    }
                  }}
                />
                <label htmlFor="radioHorizontal">Horizontal</label>
              </div> */}

                     <hr className="mt-1" />

                     {/* Layout Mode */}
                     <div className="radio-toolbar">
                        <span className="mb-2 d-block">Layout Mode</span>
                        <input
                           type="radio"
                           id="radioLightmode"
                           name="radioMode"
                           value={layoutModeTypes.LIGHTMODE}
                           checked={props.layoutModeTypes === layoutModeTypes.LIGHTMODE}
                           onChange={(e) => {
                              if (e.target.checked) {
                                 props.changeLayoutMode(e.target.value);
                              }
                           }}
                        />
                        <label className="me-1" htmlFor="radioLightmode">
                           Light
                        </label>

                        <input
                           type="radio"
                           id="radioDarkMode"
                           name="radioMode"
                           value={layoutModeTypes.DARKMODE}
                           checked={props.layoutModeTypes === layoutModeTypes.DARKMODE}
                           onChange={(e) => {
                              if (e.target.checked) {
                                 props.changeLayoutMode(e.target.value);
                              }
                           }}
                        />
                        <label htmlFor="radioDarkMode">Dark</label>
                     </div>

                     <hr className="mt-1" />

                     {/* <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Layout Width
                </span>
                <input
                  type="radio"
                  id="radioFluid"
                  name="radioWidth"
                  value={layoutWidthTypes.FLUID}
                  checked={props.layoutWidth === layoutWidthTypes.FLUID}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutWidth(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioFluid">Fluid</label>
                <input
                  type="radio"
                  id="radioBoxed"
                  name="radioWidth"
                  value={layoutWidthTypes.BOXED}
                  checked={props.layoutWidth === layoutWidthTypes.BOXED}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutWidth(e.target.value);
                    }
                  }}
                />
                <label htmlFor="radioBoxed" className="me-2">
                  Boxed
                </label>
              </div> */}
                     <hr className="mt-1" />

                     {/* <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Topbar Theme
                </span>
                <input
                  type="radio"
                  id="radioThemeLight"
                  name="radioTheme"
                  value={topBarThemeTypes.LIGHT}
                  checked={props.topbarTheme === topBarThemeTypes.LIGHT}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeTopbarTheme(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioThemeLight">Light</label>
                <input
                  type="radio"
                  id="radioThemeDark"
                  name="radioTheme"
                  value={topBarThemeTypes.DARK}
                  checked={props.topbarTheme === topBarThemeTypes.DARK}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeTopbarTheme(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioThemeDark">Dark</label>
              </div> */}

                     {/* {props.layoutType === "vertical" ? (
                <React.Fragment>
                  <hr className="mt-1" />
                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Type{" "}
                    </span>
                    <input
                      type="radio"
                      id="sidebarDefault"
                      name="sidebarType"
                      value={leftSidebarTypes.DEFAULT}
                      checked={props.leftSideBarType === leftSidebarTypes.DEFAULT}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value);
                        }
                      }}
                    />
                    <label className="me-1" htmlFor="sidebarDefault">Default</label>
                    <input
                      type="radio"
                      id="sidebarCompact"
                      name="sidebarType"
                      value={leftSidebarTypes.COMPACT}
                      checked={props.leftSideBarType === leftSidebarTypes.COMPACT}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value);
                        }
                      }}
                    />
                    <label className="me-1" htmlFor="sidebarCompact">Compact</label>
                    <input
                      type="radio"
                      id="sidebarIcon"
                      name="sidebarType"
                      value={leftSidebarTypes.ICON}
                      checked={props.leftSideBarType === leftSidebarTypes.ICON}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value);
                        }
                      }}
                    />
                    <label className="me-1" htmlFor="sidebarIcon">Icon</label>
                  </div>

                  <hr className="mt-1" />

                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Color Options
                    </span>
                    <input
                      type="radio"
                      id="leftsidebarThemelight"
                      name="leftsidebarTheme"
                      value={leftSideBarThemeTypes.LIGHT}
                      checked={props.leftSideBarTheme === leftSideBarThemeTypes.LIGHT}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value);
                        }
                      }}
                    />
                    <label className="me-1" htmlFor="leftsidebarThemelight">Light</label>
                    <input
                      type="radio"
                      id="leftsidebarThemedark"
                      name="leftsidebarTheme"
                      value={leftSideBarThemeTypes.DARK}
                      checked={props.leftSideBarTheme === leftSideBarThemeTypes.DARK}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value);
                        }
                      }}
                    />
                    <label className="me-1" htmlFor="leftsidebarThemedark">Dark</label>
                  </div>
                  <hr className="mt-1" />
                </React.Fragment>
              ) : null} */}
                     {/* 
              <h6 className="text-center">Choose Layouts</h6>

              <div className="mb-2">
                <Link to="#" target="_blank">
                  <img src={layout1} className="img-fluid img-thumbnail" alt="" />
                </Link>
              </div> */}

                     {/* <div className="mb-2">
                        <Link to="#" target="_blank">
                           <img src={layout2} className="img-fluid img-thumbnail" alt="" />
                        </Link>
                     </div>

                     <div className="mb-2">
                        <Link to="#" target="_blank">
                           <img src={layout3} className="img-fluid img-thumbnail" alt="" />
                        </Link>
                     </div>

                     <Link to="#" className="btn btn-primary btn-block mt-3" target="_blank">
                        <i className="mdi mdi-cart ms-1" /> Purchase Now
                     </Link> */}
                  </div>
               </div>
            </SimpleBar>
         </div>
         <div className="rightbar-overlay"></div>
      </React.Fragment>
   );
};

RightSidebar.propTypes = {
   changeLayout: PropTypes.func,
   changeLayoutMode: PropTypes.func,
   changeLayoutWidth: PropTypes.func,
   changeSidebarTheme: PropTypes.func,
   changeSidebarType: PropTypes.func,
   changeTopbarTheme: PropTypes.func,
   layoutType: PropTypes.any,
   layoutModeTypes: PropTypes.any,
   layoutWidth: PropTypes.any,
   leftSideBarTheme: PropTypes.any,
   leftSideBarType: PropTypes.any,
   showRightSidebarAction: PropTypes.func,
   topbarTheme: PropTypes.any,
   onClose: PropTypes.func,
};

const mapStateToProps = (state) => {
   return { ...state.Layout };
};

export default connect(mapStateToProps, {
   changeLayout,
   changeLayoutMode,
   changeSidebarTheme,
   changeSidebarType,
   changeLayoutWidth,
   changeTopbarTheme,
   showRightSidebarAction,
})(RightSidebar);
